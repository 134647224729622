import { Big } from 'big.js'
import type { TFunction } from 'next-i18next'
import type { OTF } from 'constants/otfs'

export const getSelectedOtfBridge = (
  data: OTF,
  amount: string,
  t: TFunction,
): {
  image: string
  url: string
  bridgeMessage: string
} => {
  if (data.bridge === 'amphor') {
    return {
      image: 'amphor-bridge',
      url: 'https://amphor.hl.superbridge.app/',
      bridgeMessage: t('externalBridge.AmphorBridge'),
    }
  }

  if (data.bridge === 'stakestone') {
    return {
      image: 'stakestone-bridge',
      url: 'https://app.stakestone.io/u/eth/bridge',
      bridgeMessage: t('externalBridge.StakestoneBridge'),
    }
  }

  if (data.bridge === 'stargate') {
    return {
      image: 'stargate-bridge',
      url: 'https://stargate.finance/bridge',
      bridgeMessage: t('externalBridge.StargateBridge'),
    }
  }

  if (data.bridge === 'free' && Big(amount || '0').gt(0.5)) {
    return {
      image: 'free-bridge',
      url: 'https://tunnel.free.tech/',
      bridgeMessage: t('externalBridge.FreeBridge'),
    }
  }

  if (data.bridge === 'free' && Big(amount || '0').lte(0.5)) {
    return {
      image: 'free-bridge',
      url: 'https://app.free.tech/',
      bridgeMessage: t('externalBridge.FreeBridge'),
    }
  }

  if (data.bridge === 're7-hyperlane') {
    return {
      image: 'hyperlane-bridge',
      url: 'https://re7lrt.hl.superbridge.app/',
      bridgeMessage: t('externalBridge.HyperlaneBridge'),
    }
  }

  return {
    image: 'hyperlane-bridge',
    url: 'https://renzo.hyperlane.xyz/',
    bridgeMessage: t('externalBridge.HyperlaneBridge'),
  }
}
