import { Checkbox, Flex, Input, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { useThemeColors } from 'hooks/useThemeColors'

type CustomReceivingAddressInputProps = {
  isEnabled: boolean
  toggleIsEnabled: () => void
  value: string
  onChange: (value: string) => void
  isValid: boolean
}

export const CustomReceivingAddressInput = ({
  isEnabled,
  toggleIsEnabled,
  value,
  onChange,
  isValid,
}: CustomReceivingAddressInputProps) => {
  const { t } = useTranslation(['common'])
  const COLORS = useThemeColors()

  return (
    <Flex direction="column" gap={1}>
      <Checkbox w="max-content" isChecked={isEnabled} onChange={toggleIsEnabled}>
        <Text variant="text3regular" color={COLORS.grey03}>
          {t('CustomReceivingAddress')}
        </Text>
      </Checkbox>
      {isEnabled && (
        <>
          <Input
            value={value}
            onChange={({ target }) => {
              onChange(target.value)
            }}
            placeholder="0x0000000000000000000000000000000000000000"
            {...(value &&
              !isValid && {
                borderColor: COLORS.error,
                _focus: { borderColor: 'none' },
                _hover: { borderColor: 'none' },
                _active: { borderColor: 'none' },
              })}
          />
          {value && !isValid && (
            <Text variant="text3medium" color={COLORS.error}>
              {t('common:InvalidReceiverAddress')}
            </Text>
          )}
        </>
      )}
    </Flex>
  )
}
